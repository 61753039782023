import styled from "styled-components";

export const BurgerWrapper = styled.button`
  outline: none;
  border: 2px solid #ffffff;
  cursor: pointer;
  background: transparent;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items:center;
  @media (min-width: 1280px) {
    padding: 20px;
    width: 80px;
    height: 80px;
  }
`;
