import styled from "styled-components";
import ResponsiveContainer from "../Container";

export const MenuSplash = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  transition: all ease-in-out 0.1s;
  user-select: none;
  background: radial-gradient(100% 160.68% at 0% 0%, #ff0000 0%, #ff5000 100%);
`;

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 108px;
  @media (min-width: 760px) {
    padding-top: 200px;
  }
  @media (min-width: 760px) and (min-height: 950px) {
    padding-top: 290px;
  }
  & .nav-item {
    font-family: "Gilroy-Bold", sans-serif;
    padding: 1rem 0;
    text-align: left;
    letter-spacing: 4px;
    color: #fafafa;
    cursor: pointer;
    &:hover {
      .nav-title {
        cursor: pointer;
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
    }
    a {
      color: #222;
    }
    .nav-title {
      transition: all 0s ease-in-out;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 0px;
        height: 2px;
        margin: 2px 0 0;
        transition: all 0.2s ease-in-out;
        transition-duration: 0.35s;
        opacity: 0;
        background-color: #ffffff;
      }
    }
  }
`;

export const TopContainer = styled(ResponsiveContainer)`
  flex: 1 1 0%;
  height: 100%;
  & > div {
    flex-direction: column;
  }
`;

export const SecondaryNavigation = styled.div`
  flex-direction: column;
`;

export const Social = styled.div`
  width: 100%;
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  @media (min-width: 760px) {
    justify-content: flex-end;
  }
  a {
    padding: 10px;
  }
`;

export const ContactDetails = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 760px) {
    flex-wrap: initial;
  }
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 30px;
  padding-bottom: 20px;
  & span {
    font-family: Gilroy-Light;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: 1px;
    padding-left: 10px;
    & b {
      display: block;
      font-family: Gilroy-Bold;
      @media (min-width: 760px) {
        padding-left: 60px;
        display: inline;
      }
    }
  }
`;

export const Address = styled.div`
  padding-right: 10px;
  @media (min-width: 760px) {
    margin-left: auto;
  }
`;

export const Brand = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-bottom: 10px;
  color: #ffffff;
  @media (min-width: 760px) {
    width: initial;
    display: inline;
  }
`;

export const NavigationFooter = styled(ResponsiveContainer)`
  box-sizing: border-box;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  height: auto;

  @media (min-width: 1280px) {
    padding-bottom: 60px;
    display: flex;
    align-items: flex-end;
    bottom: 0;
  }
  @media (min-width: 1280px) and (min-height: 760px) {
    padding-bottom: 120px;
  }
`;
export const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
