import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body {
        background: linear-gradient(135deg, #222 0%, #000 100%);
        font-family: "Gilroy-Regular", sans-serif;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 24px;
        color: #fafafa;
        margin: 0;
    }

    h1,
    h2 {
        font-family: "Gilroy-Bold", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        letter-spacing: 0.2px;
        line-height: 2rem;
        margin: 0;
        padding-bottom: 10px;
    }
    @media (min-width: 760px) {
        h1,
        h2 {
            font-size: 3rem;
            line-height: 3rem;
        }
    }

    h3 {
        font-family: "Gilroy-Regular", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        letter-spacing: 0.2px;
        line-height: 1.5rem;
        margin: 0;
        padding-bottom: 10px;
    }

    h4 {
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 18px;
        letter-spacing: 0.2px;
        line-height: 18px;
        color: #fafafa;
        margin: 0;
    }

    h5 {
        font-family: "Gilroy-Light", sans-serif;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 4px;
        color: #fafafa;
        margin: 0;
        padding-bottom: 20px;
    }
    p {
        margin: 0;
        padding: 10px 0;
    }

    a {
        color: #fafafa;
        text-decoration: none;
    }

    .snackbar-message {
        text-align: center;
    }

    .raisedButton {
        letter-spacing: 2px;
    }

    .gatsby-resp-image-image,
    .gatsby-resp-image-background-image {
        border-radius: 8px;
    }

    ul {
        padding: 0;
        margin: 1rem 0 1rem 0;
        list-style-type: none;
    }

    @media screen and (min-width: 980px) {
        .gatsby-resp-image-wrapper {
            min-width: calc(33% - 1rem);
            margin: 2rem 0 0 0 !important;
            max-width: inherit !important;
        }
    }

    @media screen and (max-width: 980px) {
        .gatsby-resp-image-wrapper {
            min-width: calc(50% - 1rem);
            margin: 2rem 0 0 0 !important;
            max-width: inherit !important;
        }
    }

    @media screen and (max-width: 640px) {
        .gatsby-resp-image-wrapper {
            min-width: 100%;
            margin-top: 2rem;
        }
    }

    .cc-window {
        position: fixed;
        z-index: 1;
        bottom: 0px;
        width: 33%;
        border-radius: 8px;
        margin: 2rem;
        padding: 1rem;
    }

    .cc-compliance {
        margin-top: 1rem;
        background: #ff5c00;
        text-align: center;
        line-height: 2rem;
        border-radius: 20px;
        width: 25%;
    }

    .cc-link {
        color: #ff5c00 !important;
    }
    .content {
        padding-top: 100px;
        @media(min-width: 760px) {
            padding-top: 120px;
        }
        @media(min-width: 1280px) {
            padding-top: 0;
        }
    }
`;

export default GlobalStyle;
