import React from "react";
import { Link } from "gatsby";
import CookieConsent, { Cookies } from "react-cookie-consent";
import styled from "styled-components";

const CookieConsentComponent = () => {
  return (
    <Outer>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="BandPCookies"
        cookieValue="false"
        style={{ background: "#222", color: "#FAFAFA" }}
        buttonStyle={{
          height: "40px",
          background: "#FAFAFA",
          color: "#222",
          fontSize: "14px",
          padding: "0 20px",
          borderRadius: "20px",
          fontFamily: "Gilroy-Bold",
        }}
        expires={150}
        onAccept={() => {
          window.dataLayer = window.dataLayer || [];
          window.gtag("js", new Date());
          window.gtag("config", "G-Z8NW9X2WJ3");
          Cookies.set("gtag", "true");
        }}
        onDecline={() => {
          Cookies.set("gtag", "false");
        }}
        flipButtons
        enableDeclineButton
        declineButtonText="Nein, Danke."
        declineButtonStyle={{
          background: "transparent",
          color: "#FAFAFA",
          fontSize: "14px",
          letterSpacing: "0.2px",
          padding: 0,
          margin: "1rem",
          fontFamily: "Gilroy-Regular",
        }}
      >
        Diese Webseite nutzt Cookies um Dir die bestmögliche Browsing-Erfahrung
        zu bieten.&nbsp;
        <Link to="/imprint" style={{ textDecoration: "underline" }}>
          Mehr erfahren
        </Link>
      </CookieConsent>
    </Outer>
  );
};

export default CookieConsentComponent;

export const Outer = styled.div`
  & button:last-of-type {
    order: 1;
  }
  & button:first-of-type {
    order: 2;
  }
`;
