import React, { useState, createContext } from "react";
import Helmet from "react-helmet";
import CookieConsent from "../components/CookieConsent";
import Header from "../components/Header";
import GlobalStyle from "./globalStyles";
export const Toggle = createContext(null);

const TemplateWrapper = ({ children }) => {
  const [toggledOn, setToggledOn] = useState(false);
  const toggle = () => setToggledOn(!toggledOn);

  return (
    <Toggle.Provider value={{ toggle, toggledOn }}>
      <GlobalStyle />
      <Helmet
        title="Brains & Pixels - Konzept, Branding, UI & UX Design"
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
        ]}
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      >
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-Z8NW9X2WJ3"
        ></script>
        <script>
          {`
            function getCookie(cname) {
              if (typeof window === undefined){
                return;
              }
              var name = cname + "=";
              var decodedCookie = decodeURIComponent(document.cookie);
              var ca = decodedCookie.split(';');
              for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                  c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            }
            function gtag(){dataLayer.push(arguments);}
            function activateGTag(){
              if (typeof window === undefined){
                return;
              }
              window.dataLayer = window.dataLayer || [];
              gtag('js', new Date());
              gtag('config', 'G-Z8NW9X2WJ3');
            }
            if(getCookie("gtag") === "true"){
              activateGTag();
            }
          `}
        </script>
      </Helmet>
      <Header />
      <CookieConsent />
      <div
        className="content"
        style={{
          filter: toggledOn ? "blur(25px)" : "inherit",
          WebkitFilter: toggledOn ? "blur(25px)" : "inherit",
          mixBlendMode: toggledOn ? "multiply" : "inherit",
          transition: "all ease-in-out 0.23s",
        }}
      >
        {children}
      </div>
    </Toggle.Provider>
  );
};

export default TemplateWrapper;
