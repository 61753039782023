import React, { useContext } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useStaticQuery, graphql } from "gatsby";
import { Toggle } from "../../layouts";
import { navigate } from "gatsby";
import {
  MenuSplash,
  NavigationItems,
  TopContainer,
  SecondaryNavigation,
  Social,
  ContactDetails,
  Address,
  NavigationFooter,
  Brand,
  Scrollable,
} from "./navigation.styled";
import { LinkedIn, Twitter, Xing, Instagram } from "../Icons";
import ScrollLock from "react-scrolllock";

const Navigation = () => {
  const { toggle, toggledOn } = useContext(Toggle);
  const { site } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          content {
            navigation {
              name
              title
              slug
            }
            navigationSecondary {
              name
              title
              slug
            }
          }
        }
      }
    }
  `);
  const navigationItems = site.siteMetadata.content.navigation;
  const navigationSecondaryItems =
    site.siteMetadata.content.navigationSecondary;

  const handleClick = ({ slug, name }) => {
    if (typeof window === "undefined") {
      return;
    }
    if (window.location.pathname === "/" && slug === "/") {
      scrollTo(`#${name}`);
      return;
    }
    if (window.location.pathname !== "/" && slug === "/") {
      navigate(`${slug}#${name}`);
      return;
    }
    navigate(`/${slug}`);
  };
  return (
    <ScrollLock>
      <MenuSplash onClick={toggle} onKeyDown={toggle}>
        <Scrollable>
          <TopContainer>
            <NavigationItems>
              {navigationItems.map((item, i) => (
                <div
                  className="nav-item"
                  role="button"
                  key={i}
                  onClick={() => handleClick(item)}
                >
                  <span className="nav-title">{item.title}</span>
                </div>
              ))}

              <SecondaryNavigation>
                {navigationSecondaryItems.map((item, i) => (
                  <div
                    className="nav-item"
                    role="button"
                    key={i}
                    onClick={() => handleClick(item)}
                  >
                    <span className="nav-title">{item.title}</span>
                  </div>
                ))}
              </SecondaryNavigation>
            </NavigationItems>
          </TopContainer>

          <NavigationFooter>
            <Social>
              <a href="https://www.linkedin.com/in/philipp-k%C3%B6rner-4a1938175/" target="_blank">
                <LinkedIn />
              </a>
              <a href="https://www.xing.com/profile/Philipp_Koerner5/cv" target="_blank">
                <Xing />
              </a>
              <a href="https://twitter.com/Brainsandpixels" target="_blank">
                <Twitter />
              </a>
              <a href="https://www.instagram.com/phil.koerner/" target="_blank">
                <Instagram />
              </a>
            </Social>
            <ContactDetails>
              <Brand>
                <b>Brains & Pixels</b>
              </Brand>
              <Address>
                <span>Schusterstraße 38</span>
                <span>79098 Freiburg</span>
              </Address>
            </ContactDetails>
          </NavigationFooter>
        </Scrollable>
      </MenuSplash>
    </ScrollLock>
  );
};

export default Navigation;
