import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ResponsiveContainer = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: inherit;
  box-sizing: border-box;
  overflow-x: hidden;
  @media (min-width: 760px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 1280px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
  }
  @media (min-width: 1920px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
  }
`;
const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Container = ({ children, ...rest }) => (
  <ResponsiveContainer {...rest}>
    <Row>{children}</Row>
  </ResponsiveContainer>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
