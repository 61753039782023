import styled from "styled-components";
import Container from "../Container";
import BurgerButton from "../Burger";
import Link from "gatsby-link";

export const Outer = styled.div`
  .headroom--pinned {
    background: #212121;
  }
  @media (min-width: 1280px) {
    .headroom {
      transform: initial !important;
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  padding-bottom: 20px;
  padding-top: 20px;
  @media (min-width: 760px) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  @media (min-width: 1280px) {
    background: transparent;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

export const Burger = styled(BurgerButton)`
  z-index: 200;
  top: 20px;
  @media (min-width: 760px) {
    top: 40px;
  }
  @media (min-width: 1280px) {
    top: 80px;
    position: fixed;
    margin-left: 1040px;
  }
  @media (min-width: 1920px) {
    top: 80px;
    margin-left: 1230px;
  }
`;

export const LogoContainer = styled(Container)`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 1280px) {
    overflow: visible;
  }
`;

export const LogoLink = styled(Link)`
  height: 40px;
  z-index: 9999;
  svg {
    width: 120px;
    height: 40px;
  }
  @media (min-width: 1280px) {
    height: 60px;
    position: ${({ on }) => (on ? "fixed" : "initial")};
    top: ${({ on }) => (on ? "80px" : "initial")};
    svg {
      width: 180px;
      height: 60px;
    }
  }
`;
