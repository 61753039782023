import React, { useContext } from "react";
import { Toggle } from "../../layouts";
import { BurgerClose, BurgerOpen } from "../Icons";
import { BurgerWrapper } from "./burger.styled";

const Burger = ({ className }) => {
  const { toggle, toggledOn } = useContext(Toggle);

  return (
    <BurgerWrapper className={className} onClick={toggle}>
      {toggledOn ? <BurgerClose /> : <BurgerOpen />}
    </BurgerWrapper>
  );
};

export default Burger;
