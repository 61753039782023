import React, { useContext } from "react";
import { Logo } from "../Icons.js";
import Navigation from "../Navigation/Navigation";
import {
  HeaderWrapper,
  Burger,
  LogoContainer,
  LogoLink,
  Outer,
} from "./header.styled";
import { Toggle } from "../../layouts";
import Headroom from "react-headroom";
import { useWindowSize } from "./useWindowSize";
const Header = () => {
  const { toggledOn } = useContext(Toggle);
  const windowSize = useWindowSize();
  const disableHeadroom = windowSize.width && windowSize.width >= 1280;
  return (
    <Outer >
      <Headroom disable={disableHeadroom}>
        <HeaderWrapper>
          <LogoContainer>
            <LogoLink to="/" on={toggledOn}>
              <Logo
                colorA={toggledOn ? "#ffffff" : "#FF0000"}
                colorB={toggledOn ? "#ffffff" : "#FF5000"}
              />
            </LogoLink>
            <Burger />
          </LogoContainer>
          {toggledOn && <Navigation />}
        </HeaderWrapper>
      </Headroom>
    </Outer>
  );
};

export default Header;
